import React from "react";

import "../styles/logos.css";

import offWhiteLogo from "../images/off-white-logo.png";
import mbLogo from "../images/mb-logo.png";
import paLogo from "../images/pa-logo.png";
import hpLogo from "../images/hp-logo.png";
import rmLogo from "../images/rm-logo.png";
import dpLogo from "../images/dp-logo.png";
import bertierLogo from "../images/bertier.png";
import amiriLogo from "../images/amiri-logo.png";
import casaLogo from "../images/casa-logo.png";
import palmAngelsLogo from "../images/palmangels-logo.png";
import floydLogo from "../images/floyd-logo.png";
import taschenLogo from "../images/taschen-logo.png";
import dailyPaperLogo from "../images/daily-paper-logo.png";
import fearOfGodEssentialsLogo from "../images/essentials-logo.png";

const IMAGE_SCALE = 0.5;

const LogoList = () => {
  return (
    <div className="flex gap-11 align-middle flex-shrink-0 pointer-events-auto">

      <img
        width={203 * IMAGE_SCALE}
        src={amiriLogo}
        alt="Amiri"
        style={{ flexShrink: 0, objectFit: "contain" }}
      />


      <img
        width={335 * IMAGE_SCALE}
        src={casaLogo}
        alt="Casablanca"
        style={{ flexShrink: 0, objectFit: "contain" }}
      />


      <img
        width={270 * IMAGE_SCALE}
        src={offWhiteLogo}
        alt="Off-White"
        style={{ flexShrink: 0, objectFit: "contain" }}
      />


      <img
        width={283 * IMAGE_SCALE}
        src={palmAngelsLogo}
        alt="Palm Angels"
        style={{ flexShrink: 0, objectFit: "contain" }}
      />

      <img
        width={139 * IMAGE_SCALE}
        src={floydLogo}
        alt="Floyd"
        style={{ flexShrink: 0, objectFit: "contain" }}
      />

      <img
        width={196 * IMAGE_SCALE}
        src={taschenLogo}
        alt="Taschen"
        style={{ flexShrink: 0, objectFit: "contain" }}
      />

      <img
        width={416 * IMAGE_SCALE}
        src={dailyPaperLogo}
        alt="Daily Paper"
        style={{ flexShrink: 0, objectFit: "contain" }}
      />


      <img
        width={383 * IMAGE_SCALE}
        src={fearOfGodEssentialsLogo}
        alt="Fear of God Essentials"
        style={{ flexShrink: 0, objectFit: "contain" }}
      />


    </div>
  );
};

const Logos = () => {
  return (
    <div className="p-6 flex flex-nowrap overflow-hidden fixed bottom-0">
      <div className="logo-list flex-nowrap flex-shrink-0 flex gap-11 align-middle">
        <LogoList />
        <LogoList />
        <LogoList />
      </div>
    </div>
  );
};

export default Logos;
