import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import Observer from "gsap/Observer";
import React, { useEffect, useState } from "react";
import cls from "classnames";

gsap.registerPlugin(Observer);

import "../styles/slider.css";
import { Slide } from "./Slide";

interface Props {
  imagesLoaded: () => void;
}

const Slider = ({ imagesLoaded }: Props) => {
  const [imageLoadedCount, setImageLoadedCount] = useState(0);

  useEffect(() => {
    if (imageLoadedCount >= 8) {
      imagesLoaded();
    }
  }, [imageLoadedCount]);

  useEffect(() => {
    const slidesList = [...document.querySelectorAll(".slide")];
    let slidesArr = [];
    slidesList.forEach((slide) => {
      slidesArr.push(new Slide(slide));
    });
    const totalSlides = slidesList.length;
    let current = 0;
    let isAnimating = false;

    const next = () => {
      const newPosition = current < totalSlides - 1 ? current + 1 : 0;
      navigate(newPosition);
    };

    const prev = () => {
      const newPosition = current > 0 ? current - 1 : totalSlides - 1;
      navigate(newPosition);
    };

    setInterval(() => {
      if (!isAnimating) {
        next();
      }
    }, 5000);

    const navigate = (newPosition) => {
      isAnimating = true;

      // navigation direction
      const direction =
        current < newPosition
          ? current === 0 && newPosition === totalSlides - 1
            ? "prev"
            : "next"
          : current === totalSlides - 1 && newPosition === 0
            ? "next"
            : "prev";

      const currentSlide = slidesArr[current];
      current = newPosition;
      const upcomingSlide = slidesArr[current];

      gsap
        .timeline({
          defaults: {
            duration: 1.6,
            ease: "power3.inOut",
          },
          onComplete: () => {
            currentSlide.DOM.el.classList.remove("slide--current");

            isAnimating = false;
          },
        })
        .addLabel("start", 0)

        .set(
          [currentSlide.DOM.imgInner, upcomingSlide.DOM.imgInner],
          {
            transformOrigin: direction === "next" ? "50% 0%" : "50% 100%",
          },
          "start"
        )

        // Place coming slide either above (translate -100%) or below (translate 100%) and the slide__inner to the opposite translate.
        .set(
          upcomingSlide.DOM.el,
          {
            yPercent: direction === "next" ? 100 : -100,
          },
          "start"
        )
        .set(
          upcomingSlide.DOM.inner,
          {
            yPercent: direction === "next" ? -100 : 100,
          },
          "start"
        )

        // Add current class
        .add(() => {
          upcomingSlide.DOM.el.classList.add("slide--current");
        }, "start")

        // Current slide moves either up or down (translate 100% or -100%)
        .to(
          currentSlide.DOM.el,
          {
            yPercent: direction === "next" ? -100 : 100,
          },
          "start"
        )
        .to(
          currentSlide.DOM.imgInner,
          {
            scaleY: 2,
          },
          "start"
        )
        // Upcoming slide translates to 0
        .to(
          [upcomingSlide.DOM.el, upcomingSlide.DOM.inner],
          {
            yPercent: 0,
          },
          "start"
        )
        .to(
          upcomingSlide.DOM.imgInner,
          {
            ease: "power2.inOut",
            startAt: { scaleY: 2 },
            scaleY: 1,
          },
          "start"
        );
    };

    Observer.create({
      type: "wheel,touch,pointer",
      onDown: () => !isAnimating && prev(),
      onUp: () => !isAnimating && next(),
      // invert the mouse wheel delta
      wheelSpeed: -1,
      tolerance: 10,
    });
  }, []);

  return (
    <div className="slides">
      <div className={cls("slide", { "slide--current": true })}>
        <div className="slide__inner">
          <div className="slide__img">
            <StaticImage
              src="../images/1.jpg"
              alt="Slide 1"
              className="slide__img-inner"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>

          <div className="slide__content">
            <StaticImage
              className="slide__content-img"
              src="../images/1.jpg"
              alt="Slide 1"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="slide__inner">
          <div className="slide__img">
            <StaticImage
              src="../images/2.jpg"
              alt="Slide 2"
              className="slide__img-inner"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>

          <div className="slide__content">
            <StaticImage
              className="slide__content-img"
              src="../images/2.jpg"
              alt="Slide 2"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="slide__inner">
          <div className="slide__img">
            <StaticImage
              src="../images/3.jpg"
              alt="Slide 3"
              className="slide__img-inner"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
          <div className="slide__content">
            <StaticImage
              className="slide__content-img"
              src="../images/3.jpg"
              alt="Slide 3"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="slide__inner">
          <div className="slide__img">
            <StaticImage
              src="../images/4.jpg"
              alt="Slide 4"
              className="slide__img-inner"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
          <div className="slide__content">
            <StaticImage
              className="slide__content-img"
              src="../images/4.jpg"
              alt="Slide 4"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="slide__inner">
          <div className="slide__img">
            <StaticImage
              src="../images/5.jpg"
              alt="Slide 5"
              className="slide__img-inner"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
          <div className="slide__content">
            <StaticImage
              className="slide__content-img"
              src="../images/5.jpg"
              alt="Slide 5"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="slide__inner">
          <div className="slide__img">
            <StaticImage
              src="../images/6.jpg"
              alt="Slide 6"
              className="slide__img-inner"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
          <div className="slide__content">
            <StaticImage
              className="slide__content-img"
              src="../images/6.jpg"
              alt="Slide 6"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="slide__inner">
          <div className="slide__img">
            <StaticImage
              src="../images/7.jpg"
              alt="Slide 7"
              className="slide__img-inner"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
          <div className="slide__content">
            <StaticImage
              className="slide__content-img"
              src="../images/7.jpg"
              alt="Slide 7"
              onLoad={() => setImageLoadedCount((count) => count + 1)}
              onStartLoad={({ wasCached }) => {
                if (wasCached) {
                  setImageLoadedCount((count) => count + 1);
                }
              }}
              quality={100}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
